import React, { Fragment, useState } from "react"
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import { Typography } from "@material-ui/core"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { cn } from "@/lib/utils"
import { CheckIcon, ChevronRightIcon } from "@radix-ui/react-icons"
import ProjectRetailersSelection from "./project-retailers-selection"

export const columns: ColumnDef<{ group: MaterialGroup } | MaterialItem[]>[] = [
  {
    accessorKey: "partNumber",
    header: "Part #",
    cell: ({ row }) => {
      if (row?.original?.group?.label) {
        return <div className="capitalize font-bold text-[11px] leading-[20px]">{row.original.group.label}</div>
      }
      return <div className="capitalize">{row.getValue("partNumber")}</div>
    }
  },
  {
    accessorKey: "description",
    header: "Description",
    cell: ({ row }) => <div className="capitalize">{row.getValue("description")}</div>
  },
  {
    accessorKey: "thickness",
    header: "T",
    cell: ({ row }) => <div className="capitalize">{row.getValue("thickness")}</div>
  },
  {
    accessorKey: "width",
    header: "W",
    cell: ({ row }) => <div className="capitalize">{row.getValue("width")}</div>
  },
  {
    accessorKey: "length",
    header: "L",
    cell: ({ row }) => <div className="capitalize">{row.getValue("length")}</div>
  },
  {
    accessorKey: "nominalSizes",
    header: "Nominal Sizes",
    cell: ({ row }) => <div className="capitalize">{row.getValue("nominalSizes")}</div>
  },
  {
    accessorKey: "material",
    header: "Material",
    cell: ({ row }) => <div className="capitalize">{row.getValue("material")}</div>
  },
  {
    accessorKey: "quantity",
    header: "QTY.",
    cell: ({ row }) => <div className="capitalize">{row.getValue("quantity")}</div>
  }
]

type Materials = {
  group: MaterialGroup
}[]

type MaterialGroup = {
  label: string
  items: MaterialItem[]
}

type MaterialItem = {
  description: string
  length: string
  material: string
  nominalSizes: string
  partNumber: string
  quantity: number
  thickness: string
  width: string
}

export default function ProjectMaterials({ projectId, materials }: { projectId: string; materials: Materials }) {
  const [tab, setTab] = useState<"materials" | "retailers">("materials")

  const table = useReactTable({
    data: materials,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSubRows: (originalRow) => originalRow?.group?.items
  })

  const containerRef = React.useRef<HTMLDivElement>(null)

  return (
    <div>
      <div ref={containerRef} className="flex border border-gray-300 rounded-lg p-5 gap-3 mb-10">
        <button className="text-left flex w-1/2" onClick={() => setTab("materials")}>
          <div className="rounded-full bg-rc-grey h-10 w-10 flex-shrink-0 flex items-center justify-center mr-4">
            <CheckIcon className="text-white w-5 h-5" />
          </div>

          <div>
            <Typography variant="h4" className="uppercase mb-1">
              Review the materials list
            </Typography>
            <Typography variant="body2">Review Western Red Cedar materials in the list below</Typography>
          </div>
        </button>

        <button className="flex text-left w-1/2" onClick={() => setTab("retailers")}>
          {tab === "retailers" ? (
            <div className="rounded-full bg-rc-grey h-10 w-10 flex-shrink-0 flex items-center justify-center mr-4">
              <CheckIcon className="text-white w-5 h-5" />
            </div>
          ) : (
            <div className="text-rc-grey rounded-full border-2 border-rc-grey h-10 w-10 flex-shrink-0 flex items-center justify-center mr-4">
              02
            </div>
          )}

          <div>
            <Typography variant="h4" className="uppercase mb-1">
              Choose a retailer
            </Typography>

            <Typography variant="body2">
              Select a retailer located near you. Click [Get Quote] button and fill out the information on the next page
            </Typography>
          </div>
        </button>
      </div>

      {tab === "materials" && (
        <>
          <Typography variant="h3" gutterBottom>
            Project Materials List
          </Typography>
          <div className="w-full py-4">
            <Table className="font-[HalisGr]">
              <TableHeader className="border">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id} className="font-bold text-black uppercase">
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().flatRows?.length ? (
                  table.getRowModel().flatRows.map((row, index) => {
                    const isEven = index % 2
                    const isGroup = !!row?.original?.group?.label

                    return (
                      <Fragment key={row.id}>
                        {isGroup && index !== 0 && <TableRow className="h-8" />}
                        <TableRow
                          data-state={row.getIsSelected() && "selected"}
                          className={cn(
                            "",
                            isGroup
                              ? "hover:bg-rc-light-grey bg-rc-light-grey"
                              : !isEven
                              ? "hover:bg-rc-lighter-grey bg-rc-lighter-grey border-b border-l border-r"
                              : "hover:bg-white bg-white border-b"
                          )}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                          ))}
                        </TableRow>
                      </Fragment>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      No materials.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          <div className="flex justify-end">
            <button
              onClick={() => {
                setTab("retailers")
                containerRef.current?.scrollIntoView({ behavior: "smooth" })
              }}
              className="uppercase flex items-center mb-10"
            >
              Choose Retailer{" "}
              <div className="ml-2 bg-white rounded-r h-10 w-10 flex items-center justify-center border border-gray-300">
                <ChevronRightIcon className="text-gray-500" width={30} />
              </div>
            </button>
          </div>
        </>
      )}

      {tab === "retailers" && <ProjectRetailersSelection projectId={projectId} />}
    </div>
  )
}
