import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Check, ChevronsUpDown } from "lucide-react"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command"
import { cn } from "@/lib/utils"

export default function ProjectRetailersSelection({ projectId }: { projectId: string }) {
  const retailers = useRetailers()

  const [province, setProvince] = React.useState("")
  const [city, setCity] = React.useState("")
  const [retailer, setRetailer] = React.useState("")
  const selectedRetailer = retailers.find((item) => item.title.toLowerCase() === retailer?.toLowerCase?.())

  const filteredRetailers = retailers.filter((retailer) => {
    if (province && retailer.Retailer.address.state?.trim?.()?.toLowerCase() !== province?.toLowerCase?.()) return false
    if (city && retailer.Retailer.address.city?.trim?.()?.toLowerCase() !== city?.toLowerCase?.()) return false
    return true
  })

  const filteredCities = retailers.map((item) => {
    if (item?.Retailer?.address?.state?.trim?.()?.toLowerCase() !== province?.toLowerCase?.()) return false
    return item.Retailer.address.city?.trim?.()
  })

  // reset city and retailer when province changes
  useEffect(() => {
    setCity("")
    setRetailer("")
  }, [province])
  // reset retailer when city changes
  useEffect(() => {
    setRetailer("")
  }, [city])

  return (
    <div className="mb-10">
      <div className="flex gap-x-2">
        <ProvinceComboBox retailers={retailers} value={province} onChange={setProvince} />

        <CityComboBox
          cities={filteredCities}
          retailers={retailers}
          value={city}
          onChange={setCity}
          disabled={!province}
        />

        <RetailerNameComboBox
          retailers={filteredRetailers}
          value={retailer}
          onChange={setRetailer}
          disabled={!province || !city}
        />

        <Link
          to={`/retail-quote-request?id=${selectedRetailer?.databaseId}&projectId=${projectId}`}
          aria-disabled={!retailer}
          className={cn(
            !retailer && "pointer-events-none opacity-50",
            "text-center uppercase justify-between bg-white inline-flex items-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-primary-foreground border border-gray-300 focus:border-black hover:bg-primary/90 px-4 py-2"
          )}
        >
          Get a Quote
        </Link>
      </div>
    </div>
  )
}

const ProvinceComboBox = ({ retailers, value, onChange }) => {
  const [open, setOpen] = React.useState(false)

  const states = retailers.map((retailer) => retailer.Retailer.address.state?.trim?.())
  const uniqueStates = [...new Set(states)].filter((state) => state)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "uppercase w-[200px] justify-between bg-white inline-flex items-center  text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-primary-foreground border border-gray-300 focus:border-black hover:bg-primary/90 h-9 px-4 py-2",
            open && "border-black"
          )}
        >
          {value ? uniqueStates.find((state) => state.toLowerCase() === value) : "Province / State"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 max-h-[400px] overflow-y-auto">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No state found.</CommandEmpty>
          <CommandGroup>
            {uniqueStates.map((state, index) => (
              <CommandItem
                key={index}
                onSelect={(currentValue) => {
                  onChange(currentValue === value ? "" : currentValue)
                  setOpen(false)
                }}
              >
                <Check className={cn("mr-2 h-4 w-4", value === state.toLowerCase() ? "opacity-100" : "opacity-0")} />
                {state}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

const CityComboBox = ({ cities, retailers, value, onChange, disabled }) => {
  const [open, setOpen] = React.useState(false)

  const uniqueCities = [...new Set(cities)].filter((city) => city)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          role="combobox"
          aria-expanded={open}
          className={cn(
            "uppercase w-[200px] justify-between bg-white inline-flex items-center  text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-primary-foreground border border-gray-300 focus:border-black hover:bg-primary/90 h-9 px-4 py-2",
            open && "border-black"
          )}
          disabled={disabled}
        >
          {value ? uniqueCities.find((city) => city.toLowerCase() === value) : "City"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 max-h-[400px] overflow-y-auto">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No state found.</CommandEmpty>
          <CommandGroup>
            {uniqueCities.map((city) => (
              <CommandItem
                key={city}
                onSelect={(currentValue) => {
                  onChange(currentValue === value ? "" : currentValue)
                  setOpen(false)
                }}
              >
                <Check className={cn("mr-2 h-4 w-4", value === city.toLowerCase() ? "opacity-100" : "opacity-0")} />
                {city}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

const RetailerNameComboBox = ({ retailers, value, onChange, disabled }) => {
  const [open, setOpen] = React.useState(false)

  const uniqueRetailers = retailers.filter(
    (retailer, index, self) => index === self.findIndex((t) => t.title === retailer.title)
  )

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          role="combobox"
          aria-expanded={open}
          className={cn(
            "uppercase w-[200px] justify-between bg-white inline-flex items-center  text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-primary-foreground border border-gray-300 focus:border-black hover:bg-primary/90 h-9 px-4 py-2",
            open && "border-black"
          )}
          disabled={disabled}
        >
          {value ? uniqueRetailers.find((retailer) => retailer.title.toLowerCase() === value).title.trim() : "Retailer"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0 max-h-[400px] overflow-y-auto">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No state found.</CommandEmpty>
          <CommandGroup>
            {uniqueRetailers.map((retailer) => (
              <CommandItem
                key={retailer.id}
                onSelect={(currentValue) => {
                  onChange(currentValue === value ? "" : currentValue)
                  setOpen(false)
                }}
              >
                <Check
                  className={cn("mr-2 h-4 w-4", value === retailer?.title?.toLowerCase() ? "opacity-100" : "opacity-0")}
                />
                {retailer.title.trim()}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

const useRetailers = () => {
  const {
    allWpRetailer: { nodes: retailers }
  } = useStaticQuery(graphql`
    {
      allWpRetailer {
        nodes {
          title
          id
          databaseId
          Retailer {
            quoteButton
            email
            fieldGroupName
            interestedInTraining
            phone
            quoteButton
            staffKnowledgeableWithWrc
            websiteUrl
            address {
              address1
              address2
              city
              country
              fieldGroupName
              state
              zip
            }
          }
          states {
            nodes {
              name
            }
          }
        }
      }
    }
  `)
  return retailers
}
